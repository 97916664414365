<template>
  <a-modal width="65%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 2}" :wrapper-col="{ span: 20 }">
      <a-form-model-item label="用户" prop="userList">
        <a-select mode="multiple"  style="width: 100%" v-model="form.userIdList" allowClear>
          <a-select-option v-for="(item, index) in userList" :value="item.userId" :key="index">
            {{ item.nickName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="noticeTitle">
        <a-input size="large" width="200px" v-model="form.noticeTitle" placeholder="请输入标题" />
      </a-form-model-item>
      <a-form-model-item label="简介" prop="noticeInfo">
        <a-input size="large" v-model="form.noticeInfo" placeholder="请输入简介" />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="noticeContent">
        <editor v-model="form.noticeContent" />
      </a-form-model-item>
    </a-form-model>
    <div class="bottom-control">
      <a-space>
        <a-button type="primary" :loading="submitLoading" @click="submitForm">
          发布
        </a-button>
        <a-button type="dashed" @click="cancel">
          {{$t('通用.按钮.取消')}}
        </a-button>
      </a-space>
    </div>
  </a-modal>
</template>

<script>

import { getNotice, activityMessage, updateNotice } from '@/api/activity/activity'
import Editor from '@/components/Editor'
import {uploadObject, uploadOss} from "@/api/tool/oss";

export default {
  name: 'NoticeUser',
  components: {
    Editor
  },
  data() {
    return {
      wrapperCol: { span: 48 },
      submitLoading: false,
      total: 0,
      id: undefined,
      formTitle: '',
      userList:[],
      loading: '',
      // 表单参数
      form: {
        id: undefined,
        userIdList: [],
        activityId:undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeInfo: undefined,
        noticeImg: undefined,
        noticeContent: '',
        status: 1,
        bstatus: 1
      },
      rules: {
        noticeType: [{ required: true, message: '公告类型不能为空', trigger: 'change' }],
        noticeTitle: [{ required: true, message: '公告标题不能为空', trigger: 'blur' }],
        noticeContent: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      open: false
    }
  },
  filters: {
  },
  created() {
  },
  computed: {
  },
  watch: {
  },
  mounted() {

  },
  methods: {
    changeStatus(e) {
      let status = e.target.value
      this.form.status = status === '0' ? '1' : '0'
    },
    // 表单重置
    reset() {
      this.userList = [];
      this.form = {
        id: undefined,
        userIdList: [],
        activityId:undefined,
        noticeTitle: undefined,
        noticeType: undefined,
        noticeContent: '',
        status: 1,
        bstatus: 1
      }
    },
    /** 新增按钮操作 */
    handleAdd(rows,activityId) {
      this.reset()
      this.open = true;
      this.formTitle = '添加公告'
      this.form.activityId=activityId
      this.userList = rows
      this.form.userIdList=this.userList.map(e=>e.userId)
    },
    // oss 上传开始
    async handlePreview(file) {
      let suffix = file.name.substring(file.name.lastIndexOf('.'));
      if (suffix === '.mp4') {
        this.handleVideoPreview(file);
      } else {
        this.handleImagePreview(file);
      }
    },

    async handleVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewVideoVisible = true
    },
    async handleImagePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewUrl = file.url || file.preview
      this.previewImageVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 20
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    // 上传覆盖默认的上传行为
    coverImgUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'notice'
      const url = uploadOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          _this.$set(_this.form, 'noticeImg', res)
          _this.$message.success(this.$t('通用.文本.上传成功'),)
        })
      })
    },
    // oss 上传结束
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          activityMessage(this.form).then(response => {
            this.$message.success(
              '发送成功',
              3
            )
            this.cancel()
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
  }
}
</script>
